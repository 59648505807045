
import React from "react";
import pdf from '../legal/PrivacyPolicy/privacy_policy.pdf';
import "@cyntler/react-doc-viewer/dist/index.css";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";


export const PrivacyPolicy = (props) => {

  
  return (
    <div>
      <div id="footer">
        <div className="container text-center">
          <div>
            {/* <object data={pdf} type="application/pdf" width="100%" height="100%">
              <a href = {pdf} target = "" rel="noreferer">Etiqueta Privacy Policy</a>
            </object> */}
            <DocViewer 
            documents={[{uri: pdf } ]} 
            style={{ width: "100%", height: "75%" }}
            pluginRenderers={DocViewerRenderers} />;
            <p></p>
            <a href = {pdf} target = "" rel="noreferer">Etiqueta Privacy Policy</a>
            <p></p>
          </div>
          <div>
          </div>
          <p>
            &copy; 2023 All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};
