import React from "react";

export const Howitworks = (props) => {
  return (
    <div id="howitworks" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>How it works</h2>
          <p>
            Sit back and relax, we got this.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-12">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="howitworks-desc">
                    <h3>{d.name}</h3>
                    <p style={{paddingBottom: "2em"}}>{d.text}</p>  
                  </div> 
                </div>
              ))
            : "loading"}
        </div>
        <div className="row">
          <h3>Get in touch now, and let's get started.</h3>
        </div>
      </div>
    </div>
  );
};
